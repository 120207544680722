<template>
  <aside class="mobile_aside">
    <SidebarMenu/>
  </aside>
</template>

<script>
import SidebarMenu from './SidebarMenu'

export default {
  name: 'SideBar',
  components: {
    SidebarMenu
  }
}
</script>

<style lang="stylus">

.sidebar_bottom
  fixed left bottom
  width 100%
  background $dark
  z-index 200

  ul
    display flex
    align-items center
    justify-content space-between
    padding 0 15px
    list-style none
    flex-direction initial

    li
      flex 1

      ul
        display none

      a
        padding 0
        height 60px
        display flex
        align-items center
        justify-content center
        border-radius 0

        .as__menu__link__title
        &.show_in_mobile
          display none

        &.active
          svg
            path
              stroke $orange

        +below(540px)
          &.show_in_mobile
            display flex

          &.hide_in_mobile
            display none

</style>
